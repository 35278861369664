<template>
  <div class="sessions-page">
    <div class="">
      <div class="vx-row">
        <div class="vx-col w-full mb-base">
          <div class="sessions-tabs">
            <b-nav pills class="scrollmenu">
              <b-nav-item :active="isAllCatSelected" @click="filterService('all')">All</b-nav-item>
              <b-nav-item
                v-for="(cat, index) in categories"
                :key="index"
                @click="filterService(cat.name, index)"
                :active="cat.isSelected"
              >
                {{ cat.name }}
              </b-nav-item>
            </b-nav>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div
          class="vx-col w-full sm:w-1/2 lg:w-1/4 mb-base sessions-list"
          v-for="(service, index) in filtered"
          :key="index"
        >
          <vx-card>
            <img
              :src="service.imageURL[0]"
              alt="content-img"
              class="responsive rounded-lg sessions-image"
            />
            <div class="my-6">
              <h4 class="mb-2 truncate">{{ service.title }}</h4>
              <span class="text-grey" v-if="service.educatorRef">{{service.educatorRef.user}}</span>
               <div v-html="service.description" class="text-truncate" >
                 {{ service.description }}
               </div>
              <!-- <p class="truncate">{{ service.description }}</p> -->
            </div> 
            <vs-divider></vs-divider>
            <div class="flex justify-between flex-wrap items-center">
              <span>
                <p v-if="service.packages.items" class="text-xl">
                  <span v-if="service.packages.items[0].price == 0 && service.packages.items[0].discountPrice == 0" >
                    <span class="font-semibold text-primary h5">Free</span>
                  </span>
                  <span v-else >
                    <span v-if="service.packages.items[0].discountPrice" class="font-semibold text-success"
                      >${{ service.packages.items[0].discountPrice }}</span
                    ><span
                    v-if="service.packages.items[0].price" 
                      class="text-primary ml-2"
                        :class="
                        service.packages.items[0].discountPrice
                          ? 'line-through'
                          : ''
                      "
                      >${{ service.packages.items[0].price }}</span
                    >
                  </span>
                </p>
                <!-- <div class="d-flex">
                  <div class="d-flex text-warning">
                    <span class="mr-1">4.5</span>
                    <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
                  </div>
                  <span class="ml-2 text-grey">(60 reviews)</span>
                </div> -->
              </span>
              <span>
                <vs-button
                  color="primary"
                  type="filled"
                  class="w-full"
                  @click="serviceDetails(service)"
                  >View More</vs-button
                >
              </span>
            </div>
          </vx-card>
        </div>
      </div>
    </div>

    <a @click="openAddSession()" v-if="isPartner" href="#"  class="coursefeeds-add-btn">
      <i class="fa fa-plus"></i>
    </a>
    

    <div class="">
      <vs-popup title="List My Services" :active.sync="listServices">
        <div class="p-4">
          <list-my-services-tab @formSubmitted="listServices = false" />
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from "@aws-amplify/api";
import {
  educatorServicesByEducatorId,
  educatorByUser,
  listCategorys,
  sessionBookingByUser,
  listEducatorServices
} from "@/graphql/queries";
import { Storage } from "@aws-amplify/storage";
import { createErrorObject } from "@/utils";


export default {
  name: "EducatorServices",
  data() {
    return {
      listServices: false,
      services: [],
      categories: [],
      filtered: [],
      isAllCatSelected: true
    };
  },
  components: {

  },
  computed: {
    isPartner () {
      return this.$store.state.auth.userData.isPartner
    },
    isEdit() {
      return (
        this.$store.state.auth.userData.name === this.$route.params.userName
      );
    },
  },
  created() {
    this.fetchCategories();
    this.fetchEducatorServices();
  },
  methods: {
    async openAddSession() {
      let stripeConnectedAccountKey = this.$store.state.auth.userData.stripeConnectedAccountKey;
      if(stripeConnectedAccountKey != undefined && stripeConnectedAccountKey != null){
          API.post('cpRestAPI', '/stripeaccount/accountlinks/retrieve', {
                headers: {
                  'Content-Type': 'application/json'
                },
                body: {
                  stripeConnectedAccountKey:this.$store.state.auth.userData.stripeConnectedAccountKey
              }}).then(res => {
                if(res.success === true){  
                  let details_submitted = res.response.details_submitted;
                  if(details_submitted === true){                    
                    this.listServices = true;
                  }else{             
                    this.listServices = false;
                    this.$vs.notify(createErrorObject('Error', 'Please setup your payout account under settings page to list service'));
                  }
                }
              })
              .catch(err => {
                  console.log(err);
              })
      }else{
        this.listServices = false;
        this.$vs.notify(createErrorObject('Error', 'Please setup your payout account under settings page to list service'));
      }
    },
    getHtmlValue(val) {
      var div = document.createElement("div");
      div.innerHTML = val;
      // console.log(div.innerHTML);
      return div.innerHTML.replace(/<\/?[^>]+(>|$)/g, "");
    },
    serviceDetails(service) {
      this.$router.push(`/service-details/${service.id}`);
    },
    filterService (cat, index) {
      this.categories.map(item => item.isSelected = false)
      this.isAllCatSelected = false
      if (cat === "all") {
        this.isAllCatSelected = true
        this.filtered = this.services;
      } else {
        this.categories[index].isSelected = true
        this.filtered = this.services.filter((item) => item.category === cat);
      }
    },
    async fetchCategories() {
      try {
        const res = await API.graphql(graphqlOperation(listCategorys));
        if (
          res.data.listCategorys.items &&
          res.data.listCategorys.items.length !== 0
        ) {
          for (const item of res.data.listCategorys.items) {
            item.isSelected = false;
          }
          this.categories = res.data.listCategorys.items;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchEducatorServices(){
      try {
        this.$vs.loading()
        const res = await API.graphql(graphqlOperation(listEducatorServices,{
          filter: {status: {eq: "Active"}}
        }))
        const services = res.data.listEducatorServices.items;
        for(const s of services) {
          s.imageURL = []
          for (const img of s.images) {
            const url = await Storage.get(img);
            s.imageURL.push(url);
          }
        }  
        this.services = services
        this.filtered = services
        this.$vs.loading.close()
      } catch (err) {
        console.log(err);
      }
    },
    async fetchDetails() {
      try {
        this.$vs.loading()
        if (!this.$store.state.auth.userData.isPartner) {
          console.log('is not partner')
          const res = await API.graphql(graphqlOperation(sessionBookingByUser, {
            user: this.$store.state.auth.userData.name
          }))

          const services = res.data.sessionBookingByUser.items.map(i => i.serviceRef)
          for(const s of services) {
            s.imageURL = []
            for (const img of s.images) {
              const url = await Storage.get(img);
              s.imageURL.push(url);
            }
          }
          this.services = services
          this.filtered = services
            this.$vs.loading.close()
          return
        }
        
        const getEducatorRes = await API.graphql(
          graphqlOperation(educatorByUser, {
            user: this.$store.state.auth.userData.name,
          })
        );

        if (!getEducatorRes.data.educatorByUser.items[0]) {
          this.$vs.notify(
            createErrorObject("Error", "Cannot find educator data")
          );
          this.$vs.loading.close()
          return;
        }

        const educator = getEducatorRes.data.educatorByUser.items[0];

        const getServicesRes = await API.graphql(
          graphqlOperation(educatorServicesByEducatorId, {
            educatorID: educator.id,
          })
        );

        for (const service of getServicesRes.data.educatorServicesByEducatorID
          .items) {
          service.imageURL = [];
          for (const img of service.images) {
            const url = await Storage.get(img);
            service.imageURL.push(url);
          }
        }
        this.services = getServicesRes.data.educatorServicesByEducatorID.items;
        this.filtered = getServicesRes.data.educatorServicesByEducatorID.items;
        this.$vs.loading.close()
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
  },
};
</script>

<style scoped>
.nav-link.active {
  color: white !important;
}
</style>